@import '@core/scss/core.scss';
@import '../assets/scss/styles';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/quill-editor.component.scss';

div.ng-value-container {
    max-height: 200px;
    overflow-y: auto;
}  

div.datatable-body-cell-label {
    img {
        width: -webkit-fill-available !important;
    }
}

.mw-300 {
    max-width: 300px !important;
}