
$wayops-container: #efefef;
$wayops-container-fade: #dddbdb;
$wayops-container-darker: #cacaca;
$wayops-flag-stroke: #222222;

$wayops-primary: #eecd11;
$wayops-primary-hover: #bea202;
$wayops-primary-active: #ffeb38;
$wayops-secondary: #AA9B52;
$wayops-dark: #626267;
$wayops-light: #FCEF8D;
$wayops-primary-transparent: rgba(255, 220, 20, 0.15);

// Buttons
$wayops-primary-button-text: $wayops-dark;

// Bootstrap variables override
$primary: $wayops-primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-active-bg: $primary;
