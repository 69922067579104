@import './variables/variables';
@import '@core/scss/angular/libs/datatables.component.scss';

ngb-modal-backdrop{
  z-index: 1050 !important;
}

html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

body {
    font-size: 16px;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
}

a {
    color: $wayops-primary;

    &:hover {
        color: $wayops-primary-hover;
    }
}

.red-error {
    &.ng-invalid.ng-touched .ql-toolbar.ql-snow + .ql-container.ql-snow{  border-color: #F32021 !important;
      &.ql-editor.ql-blank::before{
        color: #A30E09 !important;
      }
    }
  }
  
  .ng-select.ng-invalid.ng-touched .ng-select-container{
    border-color: #F32021;
  }
  
  .ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder{
    color: #A30E09;
  }
  
  .red-border-form { 
    border-color: #F32021 !important;
    &::placeholder{
      color: #A30E09 !important;
    }
  }
  